import { CompetencyLevels } from "config/config";
import i18n from "i18n/i18n";
import moment from "moment";
import CategoryActions from "views/categories/CategoryActions";
import CourseActions from "views/course/CourseActions";
import EnrollActions from "views/enrollments/EnrollActions";
import ModuleActions from "views/modules/ModuleActions";
import ProgramActions from "views/program/ProgramActions";
import ScheduleActions from "views/schedule/ScheduleActions";
import TrackStudentActions from "views/schedule/TrackStudentActions";
import SeasonsActions from "views/seasons/SeasonsActions";
import AutoChargeCancellationRequestActions from "views/subscriptions/AutoChargeCancellationRequestActions";
import SubscriptionActions from "views/subscriptions/SubscriptionActions";
import TagActions from "views/tags/TagActions";
import UnitActions from "views/units/UnitActions";

const displayUnitStatus = (status) => {
  status = status === "DRAFTS" ? "DRAFT" : status;
  let statusClass =
    status === "DRAFT" ? "orange" : status === "PUBLISHED" ? "green" : "";
  return (
    <span className={`item-status ${statusClass}`}>{status.toLowerCase()}</span>
  );
};
const displayEnrollmentStatus = (status) => {
  let isTableView = true;
  if (typeof status === "object") {
    isTableView = false;
    status = status.status;
  }
  let statusClass =
    status === "CANCELLED"
      ? "orange"
      : status === "COMPLETED"
      ? "green"
      : status === "IN_PROGRESS"
      ? "blue"
      : status === "EXPIRED"
      ? "grey"
      : status === "NOT_STARTED"
      ? "orange1"
      : "";
  status =
    status === "NOT_STARTED"
      ? "NOT STARTED"
      : status === "IN_PROGRESS"
      ? "IN PROGRESS"
      : status;
  return (
    <div
      className={`item-status ${
        !isTableView && "m-auto theme-font-inter-medium"
      } ${statusClass}`}
    >
      {status.toLowerCase()}
    </div>
  );
};
const displaySeason = (key, data) => {
  return (
    <span className={`season-orange`}>
      {key.split(".").reduce((acc, curr) => acc[curr], data)}
    </span>
  );
};

const displayName = (key,val)=>{
  if(val.joined_by){
    return val.joined_by.name
  }
}

const enrollment_date = (index,val)=>{
  if(val.enrollment){
    return moment(val.enrollment.enrollment_date * 1000).format("DD/MM/YYYY hh:mm A");
  }
}

const displayAttendanceTime = (val)=>{
 return moment(val).format("hh:mm A");
}


const isItemPublished = (status) => {
  return status === "PUBLISHED";
};
const getSubscriptionType = (subscriptionType) => {
  return (
    <span>
      {subscriptionType === "SUBSCRIPTION_ONLY"
        ? "Subscription"
        : subscriptionType === "PAY_AS_YOU_GO"
        ? "Pay As You Go"
        : "Pay As You Go/Subscription"}
    </span>
  );
};
const displayCompetencyLevel = (competencyLevel) => {
  let selectedLevel = CompetencyLevels.filter(
    (singleLevel) => singleLevel.value === competencyLevel
  );
  return selectedLevel.length > 0 ? selectedLevel[0].displayValue : "";
};
const displayTags = (tagsList) => {
  const numberOfTagDisplayed = 1;
  return (
    <div className="tags-list">
      <div className="tags">
        {tagsList &&
          tagsList.length > 0 &&
          tagsList
            .slice(0, numberOfTagDisplayed)
            .map((singleTag, index) => (
              <span key={index}>{singleTag.title}</span>
            ))}
      </div>
      {tagsList && tagsList.length > numberOfTagDisplayed && (
        <span className="more">
          {i18n.t("general.moreTags", {
            count: tagsList.length - numberOfTagDisplayed,
          })}
        </span>
      )}
    </div>
  );
};

export const displayTitleWithSlug = (data) => {
  return (
    <h2 className="title">
      {data.slug ? data.title + " (" + data.slug + ")" : data.title}
    </h2>
  );
};
export const displayDuration = (key, data) => {
  return <span>{data.duration + " " + data.duration_type}</span>;
};
export const displayScheduleStatus = (key, data) => {
  let status = data.is_active ? "ACTIVE" : "INACTIVE";
  let statusClass =
    status === "INACTIVE" ? "orange" : status === "ACTIVE" ? "green" : "";
  if (!data.is_complete) {
    return <span className={`item-status orange`}>incomplete</span>;
  }
  return (
    <span className={`item-status ${statusClass}`}>{status.toLowerCase()}</span>
  );
};
export const displaySeasonDate = (date) => {
  return <span>{moment(date * 1000).format("DD MMMM")}</span>;
};
export const populateDate = (date) => {
  return <span>{moment(date * 1000).format("DD-MM-YYYY")}</span>;
};
export const populateDateTime = (date) => {
  return <span>{moment(date * 1000).format("DD-MM-YYYY hh:mm A")}</span>;
};
export const displayPriceWithDollar = (price) => {
  return price ? "$ " + price : "";
};
export const displayPriceWithType = (key, obj) => {
  return obj[key] ? obj.currency.toUpperCase() + " " + obj[key] : "";
};

export const displayAutoRenew = (autoRenew) => {
  let statusClass = autoRenew === true ? "green" : "red";
  return (
    <span className={`auto-renew ${statusClass}`}>{autoRenew.toString()}</span>
  );
};
export const displaySubscriptionType = (type) => {
  return <span className={`text-capitalize`}>{type.toLowerCase()}</span>;
};
export const displayAutoCharged = (value) => {
  return (
    <div className="position-relative">
      <label className="switch no-position">
        <input type="checkbox" checked={value} disabled />
        <span className="slider round"></span>
      </label>
    </div>
  );
};
export const displayIsFreeField = (data) => {
  return (
    <span className={`is_free ${data.is_free ? "green" : "red"} `}>
      {data.is_free === false ? "paid" : "free"}
    </span>
  );
};
export const displayTableIsFreeField = (key, data) => {
  return data.is_free === false ? "False" : "True";
};
export const subscriptionTitle = (subscription) => {
  return subscription ? subscription.title : "";
};

export const convertSeconds = (seconds) => {
  // Constants
  const SECONDS_IN_A_YEAR = 31557600; // 365.25 days per year
  const SECONDS_IN_A_MONTH = 2629746; // Average seconds in a month (30.44 days)
  const SECONDS_IN_A_WEEK = 604800; // 7 days in a week
  const SECONDS_IN_A_DAY = 86400; // 24 hours in a day
  const SECONDS_IN_AN_HOUR = 3600; // 60 minutes in an hour
  const SECONDS_IN_A_MINUTE = 60; // 60 seconds in a minute

  // Calculate years
  const years = Math.floor(seconds / SECONDS_IN_A_YEAR);
  seconds -= years * SECONDS_IN_A_YEAR;

  // Calculate months
  const months = Math.floor(seconds / SECONDS_IN_A_MONTH);
  seconds -= months * SECONDS_IN_A_MONTH;

  // Calculate weeks
  const weeks = Math.floor(seconds / SECONDS_IN_A_WEEK);
  seconds -= weeks * SECONDS_IN_A_WEEK;

  // Calculate days
  const days = Math.floor(seconds / SECONDS_IN_A_DAY);
  seconds -= days * SECONDS_IN_A_DAY;

  // Calculate hours
  const hours = Math.floor(seconds / SECONDS_IN_AN_HOUR);
  seconds -= hours * SECONDS_IN_AN_HOUR;

  // Calculate minutes
  const minutes = Math.floor(seconds / SECONDS_IN_A_MINUTE);
  seconds -= minutes * SECONDS_IN_A_MINUTE;

  // Remaining seconds
  const remainingSeconds = seconds;

  let remainingTimeParts = [];
  // Return the result
  if (years > 0) {
    if (years === 1) remainingTimeParts.push(years + " Year");
    else remainingTimeParts.push(years + " Year");
  }
  if (months > 0) {
    if (months === 1) remainingTimeParts.push(months + " Month");
    else remainingTimeParts.push(months + " Months");
  }
  if (weeks > 0 && remainingTimeParts.length < 2) {
    if (weeks === 1) remainingTimeParts.push(weeks + " Week");
    else remainingTimeParts.push(weeks + " Weeks");
  }
  if (days > 0 && remainingTimeParts.length < 2) {
    if (days === 1) remainingTimeParts.push(days + " Day");
    else remainingTimeParts.push(days + " Days");
  }
  if (hours > 0 && remainingTimeParts.length < 2) {
    if (hours === 1) remainingTimeParts.push(hours + " Hour");
    else remainingTimeParts.push(hours + " Hours");
  }
  if (minutes > 0 && remainingTimeParts.length < 2) {
    if (minutes === 1) remainingTimeParts.push(minutes + " Minute");
    else remainingTimeParts.push(minutes + " Minutes");
  }
  // console.log({
  //   years: years,
  //   months: months,
  //   weeks: weeks,
  //   days: days,
  //   hours: hours,
  //   minutes: minutes,
  //   seconds: remainingSeconds,
  // });
  return remainingTimeParts.join(" ");
};

export const displayActions = (type, record, props, viewType = "list") => {
  if (type === "tags")
    return <TagActions data={record} props={props} viewType={viewType} />;
  else if (type === "categories")
    return <CategoryActions data={record} props={props} viewType={viewType} />;
  else if (type === "units")
    return <UnitActions data={record} props={props} viewType={viewType} />;
  else if (type === "modules")
    return <ModuleActions data={record} props={props} viewType={viewType} />;
  else if (type === "subscription")
    return (
      <SubscriptionActions data={record} props={props} viewType={viewType} />
    );
  else if (type === "track_students")
    return (
      <TrackStudentActions data={record} props={props} viewType={viewType} />
    );
  else if (type === "course")
    return <CourseActions data={record} props={props} viewType={viewType} />;
  else if (type === "program")
    return <ProgramActions data={record} props={props} viewType={viewType} />;
  else if (type === "schedule")
    return <ScheduleActions data={record} props={props} viewType={viewType} />;
  else if (type === "seasons")
    return <SeasonsActions data={record} props={props} viewType={viewType} />;
  else if (type === "enrollments")
    return <EnrollActions data={record} props={props} viewType={viewType} />;
  else if (type === "cancellation_requests")
    return (
      <AutoChargeCancellationRequestActions
        data={record}
        props={props}
        viewType={viewType}
      />
    );
};
const displayMonthlyPriceWithDollar = (key, data) => {
  return "$ " + data.monthly_usd_price + "/NGN " + data.monthly_ngn_price;
};
const displayYearlyPriceWithDollar = (key, data) => {
  return "$ " + data.monthly_usd_price + "/NGN " + data.monthly_ngn_price;
};

const displayPrice = (key, data) => {
  return (
    <>
      <div className="mb-2 mt-8">
        <span className="badge custom-grey   text-black">
          NGN {data.monthly_ngn_price}
        </span>
        <span className="badge custom-grey text-black ms-2">
          USD {data.monthly_usd_price}
        </span>
        <span className="badge custom-grey text-black ms-2">
          CAD {data.monthly_cad_price}
        </span>
      </div>
      <div>
        <span className="badge custom-grey text-black">
          NGN {data.yearly_ngn_price}
        </span>
        <span className="badge custom-grey text-black ms-2">
          USD {data.yearly_usd_price}
        </span>
        <span className="badge custom-grey text-black ms-2">
          CAD {data.yearly_cad_price}
        </span>
      </div>
    </>
  );
};

const displayTitle = (key, data) => {
  return (
    <>
      <div>
        <span>{data?.title}</span>
      </div>
      <div className="mt-2">
        <span className="badge custom-green px-3">Monthly</span>
      </div>
      <div>
        <span className="badge custom-blue mt-2 px-3">Yearly</span>
      </div>
    </>
  );
};

const displayCredits = (key, data) => {
  return (
    <>
      <div className="mt-8">{data?.monthly_credits}</div>
      <div className="mt-2">{data?.yearly_credits}</div>
    </>
  );
};

export const displayCreditsExpired = (key, data) => {
  return (
    <>
      <div className="mt-8">
        {data?.monthly_credits_expires === 1
          ? `${data?.monthly_credits_expires} MONTH`
          : `${data?.monthly_credits_expires} MONTHS`}
      </div>
      <div className="mt-2">
        {data?.yearly_credits_expires === 1
          ? `${data?.yearly_credits_expires} YEARS`
          : `${data?.yearly_credits_expires} YEARS`}
      </div>
    </>
  );
};

export default {
  displayUnitStatus,
  displayEnrollmentStatus,
  isItemPublished,
  displayCompetencyLevel,
  displayTags,
  displayActions,
  displayTitleWithSlug,
  displayPriceWithDollar,
  displayAutoCharged,
  displayCreditsExpired,
  displayAutoRenew,
  displaySubscriptionType,
  getSubscriptionType,
  displayIsFreeField,
  displayDuration,
  populateDate,
  populateDateTime,
  displaySeasonDate,
  displaySeason,
  subscriptionTitle,
  displayTableIsFreeField,
  convertSeconds,
  displayMonthlyPriceWithDollar,
  displayYearlyPriceWithDollar,
  displayPriceWithType,
  displayPrice,
  displayTitle,
  displayCredits,
  displayScheduleStatus,
  displayName,
  enrollment_date,
  displayAttendanceTime
};
