import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CallAPI } from "actions/General";
import LoaderComponent from "components/LoaderComponent";
import { getImagePreviewUrl } from "config/config";
import DEFAULT_IMG from "assets/images/general_old/default-image.svg";
import TICK_IMG from "assets/images/enrollment/tick.svg";
import { populateDateTime } from "actions/TableUtils";
import EnrollmentModulesUnitsList from "./EnrollmentModulesUnitsList";
import moment from "moment";
import CustomModal from "components/CustomModal";

function EnrollmentDetails() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [enrollmentDetails, setEnrollmentDetails] = useState(null);

  const getEnrollmentDetails = async (id) => {
    setLoaderType("get_details");
    const result = await CallAPI(
      "GET_ENROLLMENT_DETAILS",
      {},
      id,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      const enrolledObj = {
        ...result.data,
        timeToStart: result.data.schedule.schedule_date - moment().unix(),
        enrolledItem: result.data.enrolled_program
          ? result.data.enrolled_program
          : result.data.enrolled_course,
        enrolledVersionItem: result.data.enrolled_version.course
          ? result.data.enrolled_version.course
          : result.data.enrolled_version.program,
      };
      if (enrolledObj.enrolledItem.banner) {
        getPreviewImg(enrolledObj.enrolledItem.banner);
      }
      setEnrollmentDetails(enrolledObj);
    }
  };
  
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.record_id &&
      location.state.record_id !== ""
    ) {
      getEnrollmentDetails(location.state.record_id);
    }
  }, [location]);

  const getPreviewImg = async (id) => {
    const url = await getImagePreviewUrl(id, null);
    setImageUrl(url);
  };
  const closeModal = () => {
    setApiError("");
  };
  return (
    <div className="enrollment">
      {loader && loaderType === "get_list" ? (
        <LoaderComponent classes="mx-4" />
      ) : (
        enrollmentDetails && (
          <>
            <div className="top">
              <img
                src={
                  enrollmentDetails.enrolledItem.banner ? imageUrl : DEFAULT_IMG
                }
                alt="Unit Image"
              />
              <div className="detail">
                <div className={`column`}>
                  <div>
                    <span className="text-gray-800 fw-bold">
                      {enrollmentDetails.schedule.type === "COURSE"
                        ? "Course"
                        : "Program"}
                      :
                    </span>
                    <span className="text-gray-800">
                      {enrollmentDetails.enrolledItem.title}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-800 fw-bold">Start Date:</span>
                    <span className="text-gray-800">
                      {populateDateTime(
                        enrollmentDetails.schedule.schedule_date
                      )}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-800 fw-bold">Duration:</span>
                    <span className="text-gray-800">
                      {enrollmentDetails.enrolledItem.completion_duration +
                        " " +
                        enrollmentDetails.enrolledItem.duration_type.toLowerCase()}
                    </span>
                  </div>
                </div>
                <div className={`column`}>
                  <div>
                    <span className="text-gray-800 fw-bold">
                      Enrolled Credits:
                    </span>
                    <span className="text-gray-800">
                      {enrollmentDetails.enrolled_version.course? enrollmentDetails.enrolled_version.course.enroll_credits : enrollmentDetails.enrolled_version.program.enroll_credits}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-800 fw-bold">
                      Rewarded Credits:
                    </span>
                    <span className="text-gray-800">
                      {enrollmentDetails.credit_rewarded}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-800 fw-bold">
                      Enrollment Period:
                    </span>
                    <span className="text-gray-800">
                      {enrollmentDetails.schedule.season.title +
                        " " +
                        enrollmentDetails.schedule.year}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card course-admin-detail">
              <div className="detail m-0">
                <span className="title">What will you learn</span>
                <div className="learning-points-list">
                  {enrollmentDetails.enrolledVersionItem.learning_points.map(
                    (singlePoint, index) => (
                      <span key={index} className="">
                        <img src={TICK_IMG} alt="tick" className="me-3 ms-2" />
                        {singlePoint.text}
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="card course-admin-detail">
              <div className="detail m-0">
                <span className="title">
                  {enrollmentDetails.schedule.type === "COURSE"
                    ? "Modules List"
                    : "Courses List"}
                </span>
                <div className="learning-points-list bg-white py-9">
                  <EnrollmentModulesUnitsList
                    type={
                      enrollmentDetails.schedule.type === "COURSE"
                        ? "course"
                        : "program"
                    }
                    enrollment_id={enrollmentDetails.id}
                    timeToStart={enrollmentDetails.timeToStart}
                    // timeToStart={0}
                  />
                </div>
              </div>
            </div>
          </>
        )
      )}
      {apiError && (
        <CustomModal
          text={apiError}
          variant="error"
          header=""
          onClose={() => {
            closeModal();
          }}
          buttons={[
            {
              text: "ok",
              variant: "blue-1",
              onClick: "closeModal",
              loading: false,
              disabled: false,
            },
          ]}
          functionsList={{ closeModal }}
        />
      )}
    </div>
  );
}

export default EnrollmentDetails;
