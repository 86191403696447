import React, { useEffect, useState } from "react";
import { TrackStudentsHeader } from "./utils";
import TableComponent from "components/TableComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import { useLocation } from "react-router-dom";
import TrackStudentActions from "./TrackStudentActions";
import { CallAPI } from "actions/General";

function TrackStudents() {
    const location = useLocation();
  const userRole = useRecoilValue(getUserRole);
  const [trackStudents, setTracStudents] = useState([])

  const fetchScheduleDetails = async () => {
    try {
         let result = await CallAPI(
           "FETCH_SCHEDULE_DETAILS",
           null,
           location.state.meeting_id,
           null,
           null,
           null
         );
         if (result.status) {
           console.log(result.data);
         setTracStudents(result.data)
         }
       } catch (error) {
         console.log(error);
       }
 }

 useEffect(()=>{
   fetchScheduleDetails()
 },[])


  return (
    <div className="card mb-4">
      <ListingHeaderComponent
        type={"track_students"}
        setDisplayType={TrackStudentActions}
        displayType={""}
      />
      
      <div className={"card-body py-4"}>
        <TableComponent
          data={trackStudents}
          header={TrackStudentsHeader}
          type="track_students"
        />
      </div>
    </div>
  );
}

export default TrackStudents;
