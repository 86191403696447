import React, { useEffect, useState } from "react";
import { ViewAttendanceHeader } from "./utils";
import TableComponent from "components/TableComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import { useLocation } from "react-router-dom";
import { CallAPI } from "actions/General";

function ViewAttendance() {
  const location = useLocation(); 

  const [attendance, setAttendence] = useState([]);

  const fetchScheduleDetails = async () => {
     try {
          let result = await CallAPI(
            "FETCH_SCHEDULE_DETAILS",
            null,
            location.state.meeting_id,
            null,
            null,
            null
          );
          if (result.status) {
            console.log(result.data);
          setAttendence(result.data)
          }
        } catch (error) {
          console.log(error);
        }
  }

  useEffect(()=>{
    fetchScheduleDetails()
  },[])
  return (
    <div className="card mb-4">
      <ListingHeaderComponent
        type={"view_attendance"}
        setDisplayType={() => {}}
        displayType={""}
      />
      <div className={"card-body py-4"}>
        <TableComponent
          data={attendance}
          header={ViewAttendanceHeader}
          type="view_attendance"
        />
      </div>
    </div>
  );
}

export default ViewAttendance;
